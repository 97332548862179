import React from 'react'
import {Link} from 'gatsby'
import Layout from 'components/layout'
import breakingUp from 'assets/images/breaking-up.png'
import {localizedPaths} from 'utils'

export default function NotFound(props) {
  const page = {
    title: `☢️ You're breaking up | Inhabit 🌳`,
    id: '404',
  }

  // mutate pageContext
  props.pageContext.lang = 'en'
  props.pageContext.localizedPaths = localizedPaths

  return (
    <Layout pageInfo={page} {...props}>
      <div className="text-center my-24 mx-auto">
        <h1 className="text-7xl text-orange">
          They Have Made
          <br />A Desert Of Our World
        </h1>
        <Link to="/">
          <img
            src={breakingUp}
            alt="Goodbye! You're breaking up"
            className="max-w-lg w-full my-12 mx-auto"
          />
        </Link>
        <p className="text-5xl font-sans text-olive max-w-3xl m-auto">
          There is nothing here for us, or you. Try reading{' '}
          <Link
            to="/read"
            className="font-extended uppercase no-underline text-3xl hover:cursor-pill"
          >
            this
          </Link>{' '}
          instead
        </p>
      </div>
    </Layout>
  )
}
